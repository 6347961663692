function menu() {
    const burger = document.querySelector('.header__burger');
    const menu = document.querySelector('.nav');
    const closed = document.querySelector('.nav-closed');
    const header = document.querySelector('.header');

    burger.addEventListener('click', function () {
        menu.classList.toggle('active');
        document.body.classList.toggle('scroll-hide');
        header.classList.toggle('color-change-two');
    });

    closed.addEventListener('click', function () {
        menu.classList.remove('active');
        document.body.classList.remove('scroll-hide');
        header.classList.remove('color-change-two');
    });

}

menu();