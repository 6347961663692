document.addEventListener('DOMContentLoaded', function () {


    if (document.querySelector(".section")) {
        if (document.body.offsetWidth > 1024) {

            gsap.registerPlugin(ScrollTrigger);

            let scrollContainer = document.querySelector(".container");
            let nav = document.querySelector('.nav');
            let header = document.querySelector('.header');
            let sections = gsap.utils.toArray(".section");
            let container = document.querySelector(".container");

            document.querySelectorAll(".anchor").forEach(element => {
                element.addEventListener('click', function (e) {
                    e.preventDefault();

                    nav.classList.remove('active');
                    document.body.classList.remove('scroll-hide');
                    header.classList.add('hide');

                    const id = this.getAttribute('href').split('#')[1];
                    const targetElement = document.getElementById(id);
                    console.log(id);
                    const scrollToHere = targetElement.offsetLeft * (scrollContainer.scrollWidth / (scrollContainer.scrollWidth - window.innerWidth));

                    gsap.to(window, {
                        scrollTo: scrollToHere,
                        duration: 2
                    })
                });

            });

            let scrollTween = gsap.to(sections, {
                x: () => -(container.scrollWidth - window.innerWidth),
                ease: "none",
                scrollTrigger: {
                    trigger: ".page",
                    invalidateOnRefresh: true,
                    pin: true,
                    scrub: 5,
                    //markers: true,
                    end: () => "+=" + container.scrollWidth,
                }
            });

            ///////////////////////////////////////////////////////////////////////////////

            const promoAnim = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: ".promo",
                    start: "left left",
                    end: () => "+=" + window.innerWidth * 2,
                    scrub: true,
                    //markers: true,
                    invalidateOnRefresh: true
                }
            });

            const promoTitle = document.querySelectorAll('.promo__title p');

            promoAnim.to('.promo__wrapper', { ease: "none", x: () => window.innerWidth * 2, })
                .to('.promo-fake', { clipPath: 'circle(180.5% at 120% 50%)' }, '<')
                .to(promoTitle[0], { x: -300 }, '<')
                .to(promoTitle[1], { x: 300 }, "<")
                .to(promoTitle[2], { x: -300 }, "<")
                .to(promoTitle[3], { x: 300 }, "<");


            ////////////////////////////////////////////////////////////////////////////////

            const historyAnim = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: '.history__purple .text-block',
                    scrub: 3,
                    //markers: true,
                    start: "right right",
                    end: "+=200% center",
                }
            });

            historyAnim.from('.history__purple .num', { x: -200 })
                .from('.history__purple .history__block--center img', { x: 200 }, "<")

            const historyAnim2 = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: '.history__img-block h3',
                    scrub: 3,
                    //markers: true,
                    start: "left right",
                    end: "center left",
                }
            });

            historyAnim2.to('.history__img-block h3', { x: -200 });

            const historyAnim3 = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: '.history__yellow .history__block',
                    scrub: 4,
                    //markers: true,
                    start: "left right",
                    end: "right left",
                }
            });


            historyAnim3.to('.history__yellow .num', { x: 900 });

            /////////////////////////////////////////////////////////////////////////////

            let number = document.querySelectorAll('.number span');
            let title = document.querySelectorAll('.title');

            title.forEach(item => {
                let sectionAnim = gsap.timeline({
                    scrollTrigger: {
                        containerAnimation: scrollTween,
                        trigger: item,
                        scrub: 3,
                        //markers: true,
                        start: "right right",
                        end: "left 70%",
                    }
                });

                sectionAnim.from(item.querySelector('span'), { x: -100 });
            });

            number.forEach(item => {
                let sectionAnim = gsap.timeline({
                    scrollTrigger: {
                        containerAnimation: scrollTween,
                        trigger: item,
                        scrub: 3,
                        //markers: true,
                        start: "right right",
                        end: "left 70%",
                    }
                });

                sectionAnim.from(item, { y: '100%' }, "<");
            });


            ////////////////////////////////////////////////////////////////////////////////////


            const mensghenImg = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: '.mensghen-img2',
                    scrub: 4,
                    //markers: true,
                    start: "left right",
                    end: "right left",
                }
            });


            mensghenImg.to('.mensghen-img2', { x: -200 });


            const mensghenImgCenter = gsap.timeline({
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: ".center-block",
                    start: "center center",
                    end: () => "+=" + window.innerWidth * 2,
                    scrub: true,
                    //markers: true,
                    invalidateOnRefresh: true
                }
            });


            mensghenImgCenter.to('.center-block', { ease: "none", x: () => window.innerWidth * 2, })
                .fromTo('.center-block-img.one', { top: '75vh', }, { top: '-41vh' }, '<')
                .fromTo('.center-block-img.two', { top: '95vh', }, { top: '-2vh' }, '<')
                .fromTo('.center-block-img.three', { top: '100vh', }, { top: '34vh' }, '<');


            ///////////////////////////////////////////////////////////////////////////////////////


            const verticalText = gsap.timeline({
                scrollTrigger: {
                    trigger: '.vertical-text',
                    containerAnimation: scrollTween,
                    scrub: 3,
                    //markers: true,
                    start: "left right",
                    end: "left left",
                }
            });


            verticalText.to('.vertical-text-first', { y: -300 })
                .to('.vertical-text-second', { y: 300 }, '<')



            const gegenwartText = gsap.timeline({
                scrollTrigger: {
                    trigger: '.gegenwart-first p',
                    containerAnimation: scrollTween,
                    scrub: 3,
                    //markers: true,
                    start: "center center",
                    end: "+=200% left",
                }
            });


            gegenwartText.to('.gegenwart-first p', { x: 2000 })


            const gegenwartGallery = gsap.timeline({
                scrollTrigger: {
                    trigger: '.gegenwart-gallery-wrap',
                    containerAnimation: scrollTween,
                    scrub: 5,
                    //markers: true,
                    start: "left right",
                    end: "right left",
                }
            });


            gegenwartGallery.to('.gegenwart-gallery-first', { y: -130, duration: 2 })
                .to('.gegenwart-gallery-third', { y: -90, duration: 1 }, '<')
        } else {


            const tl = gsap.timeline();

            tl.from('.promo__title p:nth-child(1)', { x: 1000, duration: 1, delay: 0.5 })
                .from('.promo__title p:nth-child(2)', { x: -1000, duration: 1 }, '<');


            ////////////////////////////////////////////////////////////////////


            let number = document.querySelectorAll('.number span');

            number.forEach(item => {
                let numberAnim = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        scrub: 1,
                        //markers: true,
                        start: "top bottom",
                        end: "top 85%"
                    }
                });

                numberAnim.from(item, { y: '100%' });
            });

            ////////////////////////////////////////////////////////////////////

            const gegenwartGallery = gsap.timeline({
                scrollTrigger: {
                    trigger: '.img2',
                    scrub: 4,
                    //markers: true,
                    start: "top bottom",
                    end: "top top",
                }
            });


            gegenwartGallery.to('.history__img-block .img3', { y: '-50%' })
                .to('.history__img-block .img1', { y: '-50%' }, '<');

            ////////////////////////////////////////////////////////////////////

            const centerBlock = gsap.timeline({
                scrollTrigger: {
                    trigger: '.center-block',
                    scrub: 1,
                    pin: true,
                    pinSpacing: true,
                    //markers: true,
                    start: "top top",
                    end: "+=300% bottom",
                }
            });

            centerBlock.from('.center-block-img.one', { x: '-50%' })
                .from('.center-block-img.two', { x: '-100%' }, '<')
                .from('.center-block-img.three', { x: '-100%' }, '<');


            ////////////////////////////////////////////////////////////////////


            const horizonalText = gsap.timeline({
                scrollTrigger: {
                    trigger: '.vertical-text',
                    scrub: 1,
                    //markers: true,
                    start: "top bottom",
                    end: "bottom top",
                }
            });


            horizonalText.to('.vertical-text-first', { x: -300 })
                .to('.vertical-text-second', { x: 300 }, '<');


            /////////////////////////////////////////////////////////////////


            const gegenwartGalleryHorizontal = gsap.timeline({
                scrollTrigger: {
                    trigger: '.gegenwart-gallery-wrap',
                    scrub: 3,
                    //markers: true,
                    start: "center bottom",
                    end: "+=500 top",
                }
            });


            gegenwartGalleryHorizontal.to('.gegenwart-gallery-first', { y: -130, duration: 2 })
                .to('.gegenwart-gallery-third', { y: -90, duration: 1 }, '<');



            const anchors = document.querySelectorAll('a.anchor');
            let nav = document.querySelector('.nav');

            for (let anchor of anchors) {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();

                    nav.classList.remove('active');
                    document.body.classList.remove('scroll-hide');

                    const blockID = anchor.getAttribute('href');

                    document.querySelector(blockID).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                });
            }

        }
    }


    let scrollContainer = document.querySelector(".container");

    if (window.location.hash) {
        setTimeout(function () {
            window.scroll(0, 0)
            let target = window.location.hash;
            let test = target.replace(/[^a-zа-яё]/gi, '');
            const targetElement = document.getElementById(test);
            const scrollToHere = targetElement.offsetLeft * (scrollContainer.scrollWidth / (scrollContainer.scrollWidth - window.innerWidth));

            gsap.to(window, {
                scrollTo: scrollToHere,
                duration: 3
            })

            setTimeout(function () {
                history.replaceState("", document.title, window.location.pathname + window.location.search);
            }, 2000);

        }, 500);
    }






























});