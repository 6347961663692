document.addEventListener('DOMContentLoaded', function () {


    let lastScroll = 0;
    let header = document.querySelector('.header');
    window.addEventListener('scroll', function () {
        if (window.scrollY > lastScroll) {
            header.classList.add('hide');
        } else {
            header.classList.remove('hide');
        }
        lastScroll = window.scrollY;


        if (window.scrollY > 400) {
            header.classList.add('color-change');
        } else {
            header.classList.remove('color-change');
        }
    });




});